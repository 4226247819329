import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { ApolloLink, ApolloProvider } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { sv } from 'date-fns/locale';
import { runInAction } from 'mobx';

import { theme } from './styles/theme';
import { ConfirmDialog } from './components/common/ConfirmDialog';
import { Notifications } from './components/notifications/Snackbar';
import {
  client,
  makeErrorLink,
  makeNewLink,
  subscriptionClient,
} from './stores/apollo';
import { StoreContext, store, useStores } from './stores';
import { router } from './router';
import { GlobalStyles } from './styles/GlobalStyles';

const DEV = process.env.NODE_ENV === 'development';

if (DEV) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

//Initialize client
// const store = MainStore.store;
const apolloClient = client;

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

const helmetContext = {};

export const App = observer(function App() {
  const stores = useStores();
  const store = stores.mainStore;

  const storeClient = store.client;
  const storeSubscriptionClient = store.subscriptionClient;

  if (!storeClient) {
    console.log('Adding apollo client to store');
    const errorLink = makeErrorLink(store);
    const updatedLink = makeNewLink([errorLink]);
    apolloClient.setLink(updatedLink);

    store.client = apolloClient;
    store.subscriptionClient = subscriptionClient;
  }

  useEffect(() => {
    storeSubscriptionClient?.on('connected', (sock) => {
      console.log(`WS connected`, sock);
      runInAction(() => {
        store.activeWebsocket = sock as WebSocket;
      });
    });
    storeSubscriptionClient?.on('closed', (e) => {
      console.log(`WS onDisconnected`, e);
      runInAction(() => {
        store.activeWebsocket = undefined;
      });
    });
  }, [store, storeSubscriptionClient]);

  console.log('App');

  return (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider context={helmetContext}>
        <CacheProvider value={muiCache}>
          <TssCacheProvider value={tssCache}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={sv}
            >
              <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <GlobalStyles />
                <Helmet titleTemplate={'Hjämargården - %s'} />
                <RouterProvider router={router} />
                <ConfirmDialog />
                <Notifications />
              </ThemeProvider>
            </LocalizationProvider>
          </TssCacheProvider>
        </CacheProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
});

const WrappedAppWithStore = observer(() => {
  return (
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>
  );
});

export default WrappedAppWithStore;
