import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { observer } from 'mobx-react';
import {
  Box,
  Collapse,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Event, ExpandLess, ExpandMore } from '@mui/icons-material';
import { indigo } from '@mui/material/colors';


import { DimmedText, Spacer } from '../common/CommonDivs';
import { useMobile } from '../../styles/theme';
import { useStores } from '../../stores';

import { ActiveCampList } from './ActiveCampList';
import { SecondaryNav } from './SecondaryNav';
import { SideDrawer } from './SideDrawer';
import { StyledLogoLink, StyledMainContent, useLayoutStyles } from './styles';
import { TopNavigationBar } from './TopNavigationBar';

export const Layout = observer(function Layout({
  children,
}: PropsWithChildren) {

  const isMobile = useMobile();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [campsOpen, setCampsOpen] = useState(true);
  
    // @ts-expect-error
  const { classes, cx } = useLayoutStyles();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((s) => !s);
  }, []);

  const handleCampToggle = useCallback(() => {
    setCampsOpen((s) => !s);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false);
    }
  }, [isMobile]);

  return (
    <div className={cx(classes.flexColumn)}>
      <TopNavigationBar handleDrawerToggle={handleDrawerToggle} />
      <SideDrawer onClose={handleDrawerToggle} open={mobileOpen}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <LogoLink />
          <List>
            <ListItemButton onClick={handleCampToggle}>
              <ListItemIcon>
                <Event htmlColor={indigo[500]} />
              </ListItemIcon>
              <ListItemText primary={'Läger'} />
              {campsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={campsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ActiveCampList />
              </List>
            </Collapse>
          </List>
          <Divider />
          <SecondaryNav />
          <Spacer />
          <VersionIdentifier />
        </Box>
      </SideDrawer>
      <StyledMainContent>{children}</StyledMainContent>
    </div>
  );
});

const VersionIdentifier = observer(function VersionIdentifier() {
  const [version, setVersion] = useState('');

  useEffect(() => {
    import('../../../package.json').then((data) => {
      setVersion(data.version);
    });
  }, []);

  return (
    <Box sx={{ width: '100%', textAlign: 'center', marginTop: 'auto' }}>
      <DimmedText>{version}</DimmedText>
    </Box>
  );
});

const LogoLink = observer(function LogoLink() {
  const stores = useStores();
  const linkStore = stores.linkStore;
  const linkUrl = linkStore.linkUrl;
  const logo = '/hjg_logo-black.svg';

  return (
    <>
      <StyledLogoLink>
        <Link href={linkUrl ? linkUrl : '/'}>
          <img src={logo} alt="logo" crossOrigin="anonymous" />
        </Link>
      </StyledLogoLink>
      <Divider />
    </>
  );
});
