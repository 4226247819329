import {
  ChartIcon,
  LinkIcon,
  MobileAppIcon,
  PersonsIcon,
  SettingsIcon,
  StudygroupIcon,
} from '../../components/icons';
import { PersonsDashboard } from '../../components/persons';
import { StatisticsDashboard } from '../../components/statistics';
import { StudygroupDashboard } from '../../components/studygroup';
import { User } from '../../generated/graphql';
import { DashboardItemType } from '../../types/dashboard';
import { SharedLinksDashboard } from '../../components/shared-links';
import { AppDashboard, CampSettingsDashboard } from '../../components/camp';

const campPages: DashboardItemType[] = [
  {
    name: 'Anmälningslista',
    path: 'persons',
    permissionArea: 'persons',
    icon: <PersonsIcon />,
    DashboardContent: () => <PersonsDashboard />,
  },
  {
    name: 'Appen',
    path: 'app',
    permissionArea: 'app',
    icon: <MobileAppIcon />,
    DashboardContent: () => <AppDashboard />,
  },
  {
    name: 'Speciallänkar',
    path: 'sharedlinks',
    permissionArea: 'sharedlinks',
    icon: <LinkIcon />,
    DashboardContent: () => <SharedLinksDashboard />,
  },
  {
    name: 'Statistik',
    path: 'statistics',
    icon: <ChartIcon />,
    permissionArea: 'persons',
    DashboardContent: () => <StatisticsDashboard />,
  },
  {
    name: 'Inställningar',
    path: 'settings',
    icon: <SettingsIcon />,
    DashboardContent: () => <CampSettingsDashboard />,
  },
  {
    name: 'Bildagrupper',
    path: 'studygroups',
    permissionArea: 'bilda',
    icon: <StudygroupIcon />,
    DashboardContent: () => <StudygroupDashboard />,
  },
];

export const authorizedCampPages = function authorizedCampPages(
  user: Partial<User>,
  campId: number,
) {
  const filtered = campPages.filter((item) =>
    isUserAuthForCamp(user, campId, item.path),
  );

  return filtered;
};

export const isUserAuthForCamp = function isUserAuthForCamp(
  user: Partial<User>,
  campId: number,
  subPath?: string,
) {
  if (!user || !campId) return false;
  if (user.siteAdmin) return true;

  const userCampAuth = user.userAuthorizedAreas?.find(
    (u) => u && u.camp_id === campId,
  );

  if (!userCampAuth) return false;
  if (userCampAuth.isCampEditor) return true;

  if (subPath) {
    const campPage = campPages.find((item) => item.path === subPath);

    if (!campPage) return false;

    let areas = JSON.stringify(userCampAuth.authorizedAreas);

    const areasArray = JSON.parse(areas);
    
    if (areasArray.some((item) => {
        item === campPage.permissionArea;
      })
    ) {
      return true;
    }
  } else if (userCampAuth.authorizedAreas.length) {
    return true;
  }

  return false;
};
